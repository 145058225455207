import React from "react";

export const Registration = (props) => {
  return (
    <div id="registration" className="text-center">
      <div className="container">
        <div className="col-md-10 col-md-offset-1 section-title">
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <h2>Registration and Submission</h2>
        </div>
        <br></br>
        <div className="col-md-8 col-md-offset-2 text-justify-xs">
          <div className="registration-text">
            <p>
              Registration for the congress is open. Please register using the
              following link:
            </p>
            <br></br>
            <div class="text-center">
              <a
                href="https://docs.google.com/forms/d/e/1FAIpQLSdhZ88BNyM2b0jTvBJKHGgFqPt-KQn7GlU3fJw6UO4GvhmxDw/viewform?usp=dialog"
                target="_blank"
                rel="noreferrer"
                class="btn btn-info"
                role="button"
              >
                Registration
                <br></br>Submission
                <br></br>Form
              </a>
            </div>

            <br></br>
            <p>
              Using the same link the participants can also submit their
              abstracts. If you want to present more than one paper, up to three
              additional abstracts are allowed.
            </p>
            <p>
              {" "}
              After you complete the registration/submission you will receive
              the confirmation email with the registration ID. Please save the
              registration ID for future reference.
            </p>
            <p>Full paper template... coming soon!</p>
          </div>
        </div>
      </div>
    </div>
  );
};
